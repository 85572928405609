// web-react-admin-lavabee\src\modules\videos\EditVideo\index.tsx
import React, {useCallback, useState} from "react";
import {useParams, useNavigate} from "react-router";
import {observer} from "mobx-react-lite";
import {Alert, Box, Card, CardContent, Snackbar} from "@mui/material";
import toast from "react-hot-toast";

import {mainStore, VideoPresets} from "../VideoEditor/main";
import {useVideoLoader} from "./hooks/useVideoLoader";
import {useVideoActions} from "./hooks/useVideoActions";
import {useModalStates} from "./hooks/useModalStates";
import {useVideoMetadata} from "./hooks/useVideoMetadata";
import LoadingState from "./components/LoadingState";
import VideoPlayer from "./components/VideoPlayer";
import ActionButtons from "./components/ActionButtons";
import VideoModals from "./components/VideoModals";
import VideoList from "./components/VideoList";
import VideoMetadata from "./components/VideoMetadata";

const EditVideo = observer(() => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    videoStreamingUrl,
    isLoading,
    videoError,
    data,
    dogId,
    isDogActive,
    saveVideoPresets
  } = useVideoLoader(params?.id);
  const [processProgress, setProcessProgress] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  const showDogInactiveError = useCallback(
    () =>
      toast(<DogInactiveError />, {
        duration: 3000,
        style: {display: "contents"}
      }),
    []
  );

  const modalStates = useModalStates();

  const {
    handlePost,
    handleDownload,
    cancelOperation,
    postStatus,
    downloadStatus,
    postVideoUrl,
    setPostVideoUrl
  } = useVideoActions(
    mainStore.transform.rotation,
    setProcessProgress,
    isDogActive,
    showDogInactiveError,
    modalStates.setPostModalVisible
  );

  const {metadata} = useVideoMetadata(data);

  const {ffmpeg, video} = mainStore;

  const buttonsDisabled = isLoading || postStatus || downloadStatus || isSaving;

  const handleDelete = () => {
    modalStates.setOpenDelete(true);
  };

  const handleSave = async () => {
    setIsSaving(true);
    const {transform, originalPresets} = mainStore;

    // Ensure video.duration is available
    if (!video || typeof video.duration !== "number") {
      console.error("Video duration is not available");
      setIsSaving(false);
      return;
    }

    const newPresets: VideoPresets = {
      trimStart: transform.time?.[0] ?? originalPresets?.trimStart ?? 0,
      trimEnd:
        transform.time?.[1] ?? originalPresets?.trimEnd ?? video.duration,
      rotation: transform.rotation ?? originalPresets?.rotation ?? 0
    };

    // Ensure trimEnd is not 0 or greater than the video duration
    if (newPresets.trimEnd === 0 || newPresets.trimEnd > video.duration) {
      newPresets.trimEnd = video.duration;
    }

    // Ensure trimStart is less than trimEnd
    if (newPresets.trimStart >= newPresets.trimEnd) {
      newPresets.trimStart = 0;
    }

    try {
      await saveVideoPresets(params?.id, newPresets);
    } catch (error) {
      console.error("Error saving video presets:", error);
    } finally {
      setIsSaving(false);
    }
  };

  if (!videoStreamingUrl && !videoError) {
    return <LoadingState />;
  }

  return (
    <Box display="flex">
      <Card style={{margin: "20px 5px 20px 20px", flex: 2.2}}>
        <CardContent style={{display: "flex", gap: "2rem"}}>
          <VideoMetadata metadata={metadata} />
          <Box
            display="flex"
            flexDirection="column"
            flex={2}
            justifyContent="space-between"
          >
            <VideoPlayer
              videoError={videoError}
              video={video}
              mainStore={mainStore}
              videoStreamingUrl={videoStreamingUrl}
              handleVideoRotate={mainStore.handleRotate}
              isVideoLoaded={!mainStore.fileLoading}
            />
            {!videoError && (
              <ActionButtons
                buttonsDisabled={buttonsDisabled}
                handlePost={handlePost}
                handleDownload={handleDownload}
                handleDelete={handleDelete}
                handleSave={handleSave}
                cancelOperation={cancelOperation}
                postStatus={postStatus}
                downloadStatus={downloadStatus}
                processProgress={processProgress}
                isSaving={isSaving}
                setSaveEditSuccess={modalStates.setSaveEditSuccess}
                setSaveEditError={modalStates.setSaveEditError}
              />
            )}
          </Box>
        </CardContent>
        <VideoModals
          modalStates={modalStates}
          navigate={navigate}
          dogId={dogId}
          dogName={metadata?.dogName}
          postVideoUrl={postVideoUrl}
          setPostVideoUrl={setPostVideoUrl}
          thumbnailUrl={data?.findOneVideo?.thumbnail_url}
          videoId={params?.id}
        />
      </Card>
      <Card style={{flex: 0.6, margin: "20px 20px 20px 0"}}>
        <CardContent>
          <VideoList dogName={metadata?.dogName} />
        </CardContent>
      </Card>
    </Box>
  );
});

export default EditVideo;

const DogInactiveError = () => (
  <Snackbar
    open={true}
    autoHideDuration={3000}
    anchorOrigin={{vertical: "top", horizontal: "right"}}
  >
    <Alert severity="error" sx={{width: "100%"}}>
      Sorry, this action is not allowed as this dog is currently Inactive.
    </Alert>
  </Snackbar>
);
