import gql from "graphql-tag";

const REVIEW_POST = gql`
  mutation reviewPost($input: ReviewPostDto!) {
    reviewPost(reviewPostDto: $input) {
      success
      message
      gasFees
      transaction_hash
    }
  }
`;

const CREATE_ADMIN_POST = gql`
  mutation createAdminPost($input: CreateAdminPostDto!) {
    createAdminPost(createAdminPostDto: $input) {
      success
      message
    }
  }
`;

const GET_FEED = gql`
  query listFeeds($input: FilterDto!) {
    listFeeds(listFeedDto: $input) {
      feeds {
        _id
        caption
        post_gallery {
          url
          mime_type
          thumbnail_url
        }
        session_start
        session_end
        comment_count
        product
        handler
        status
        signature
        admin_feedback
        createdAt
        updatedAt
        transaction_hash
        handlerData {
          id
          name
          createdAt
        }
        productData {
          name
          image
          status
        }
      }
      totalCount
    }
  }
`;

const GET_POST_COUNT = gql`
  query {
    GetPostCounts {
      totalPosts
      approvedPosts
      pendingPosts
      rejectedPosts
    }
  }
`;

const UNPUBLISH_POST_MUTATION = gql`
  mutation UnpublishPost($id: String!) {
    unpublishPost(unpublishPostDto: {id: $id}) {
      success
      message
    }
  }
`;

export {
  REVIEW_POST,
  GET_FEED,
  GET_POST_COUNT,
  CREATE_ADMIN_POST,
  UNPUBLISH_POST_MUTATION
};
