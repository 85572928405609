// web-react-admin-lavabee\src\modules\videos\EditVideo\components\VideoPlayer.tsx
import React, {useEffect} from "react";
import {Box, Stack, CircularProgress, Typography} from "@mui/material";
import {VideoEditor} from "../../VideoEditor";
import {runInAction} from "mobx";
import serverError from "../../../../assets/images/servererror.gif";

interface VideoPlayerProps {
  mainStore: any;
  videoError: boolean;
  video: HTMLVideoElement | undefined;
  isVideoLoaded: boolean;
  // ffmpeg: any;
  videoStreamingUrl: string | null;
  handleVideoRotate: (rotation: number) => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  mainStore,
  videoError,
  isVideoLoaded,
  video,
  videoStreamingUrl,
  handleVideoRotate
}) => {
  return (
    <Box sx={{width: "100%", maxWidth: "1000px", margin: "0 auto"}}>
      <Stack spacing={2}>
        {videoError ? (
          <ErrorState />
        ) : !isVideoLoaded ? (
          <SimpleVideoPlayer videoStreamingUrl={videoStreamingUrl} />
        ) : (
          <VideoEditorComponent
            mainStore={mainStore}
            video={video}
            handleVideoRotate={handleVideoRotate}
          />
        )}
        {!video && !videoError && <LoadingEditingTools />}
      </Stack>
    </Box>
  );
};

const ErrorState = () => (
  <Box
    height="70vh"
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    <img src={serverError} alt="error" />
    <Typography variant="h6" className="my-2">
      Could not find the video
    </Typography>
  </Box>
);

interface VideoEditorComponentProps {
  mainStore: any;
  video: HTMLVideoElement;
  handleVideoRotate: (rotation: number) => void;
}

const VideoEditorComponent: React.FC<VideoEditorComponentProps> = ({
  mainStore,
  video,
  handleVideoRotate
}) => (
  <Box sx={{position: "relative", width: "100%", paddingTop: "56.25%"}}>
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
      }}
    >
      <VideoEditor
        time={mainStore.transform.time}
        video={video}
        onRotate={handleVideoRotate}
        onChange={(time) => {
          runInAction(() => {
            mainStore.transform = {
              ...mainStore.transform,
              time
            };
          });
        }}
      />
    </Box>
  </Box>
);

interface SimpleVideoPlayerProps {
  videoStreamingUrl: string | null;
}

const SimpleVideoPlayer: React.FC<SimpleVideoPlayerProps> = ({
  videoStreamingUrl
}) => (
  <Box sx={{position: "relative", width: "100%", paddingTop: "56.25%"}}>
    <video
      controls
      controlsList="nodownload nofullscreen noremoteplayback"
      autoPlay
      src={videoStreamingUrl}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "contain",
        boxShadow: "0 0 20px 5px rgba(0, 0, 0, 0.3)"
      }}
    />
  </Box>
);

const LoadingEditingTools = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100px"
    }}
  >
    <CircularProgress sx={{marginRight: 2}} />
    <Typography variant="h6">Loading editing tools...</Typography>
  </Box>
);

export default VideoPlayer;
