import {useState, useEffect} from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import {
  GET_VIDEO_DETAILS,
  UPDATE_VIDEO
} from "../../../../shared/graphQL/video";
import {get, set} from "idb-keyval";
import {mainStore, VideoPresets} from "../../VideoEditor/main";

export const useVideoLoader = (videoId: string | undefined) => {
  const [getVideoData, {data}] = useLazyQuery(GET_VIDEO_DETAILS);
  const [updateVideoPresets] = useMutation(UPDATE_VIDEO);
  const [videoStreamingUrl, setVideoStreamingUrl] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [videoError, setVideoError] = useState(false);
  const [isDogActive, setIsDogActive] = useState(false);
  const [awsKey, setAwsKey] = useState("");
  const [awsStreamUrl, setAwsStreamUrl] = useState("");
  const [dogId, setDogId] = useState("");
  const [dogName, setDogName] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");

  const saveVideoPresets = async (videoId: string, presets: VideoPresets) => {
    try {
      const response = await updateVideoPresets({
        variables: {
          input: {
            _id: videoId,
            video_presets: JSON.stringify(presets)
          }
        }
      });

      if (response.data.updateVideoPresets) {
        mainStore.setOriginalPresets(presets);
      }
    } catch (error) {
      console.error("Error al guardar los presets del video:", error);
    }
  };

  const findBox = (arrayBuffer: ArrayBuffer, boxType: string): number => {
    const dataView = new DataView(arrayBuffer);
    const boxTypeBytes = Array.from(boxType).map((char) => char.charCodeAt(0));

    for (let i = 0; i < dataView.byteLength - 4; i++) {
      if (
        dataView.getUint8(i) === boxTypeBytes[0] &&
        dataView.getUint8(i + 1) === boxTypeBytes[1] &&
        dataView.getUint8(i + 2) === boxTypeBytes[2] &&
        dataView.getUint8(i + 3) === boxTypeBytes[3]
      ) {
        return i;
      }
    }

    return -1; // Box not found
  };

  const load = async () => {
    if (!videoId) return;

    try {
      const response = await getVideoData({
        variables: {input: {_id: videoId}}
      });

      const {
        aws_key,
        aws_stream_url,
        dog_id,
        dog_name,
        thumbnail_url,
        video_presets,
        is_active
      } = response.data.findOneVideo;

      setAwsKey(aws_key);
      setAwsStreamUrl(aws_stream_url);
      setDogId(dog_id);
      setIsDogActive(is_active === "true");
      setDogName(dog_name);
      setThumbnailUrl(thumbnail_url);

      if (video_presets) {
        const parsedPresets = video_presets ? JSON.parse(video_presets) : null;
        mainStore.setOriginalPresets(parsedPresets);
      }

      if (aws_stream_url) {
        await processFfmpegVideo(aws_stream_url);
        const fetchResponse = await fetch(aws_stream_url);
        const box = findBox(await fetchResponse.arrayBuffer(), "moov");

        if (fetchResponse.ok && box !== -1) {
          setVideoStreamingUrl(aws_stream_url);
          setVideoError(false);
        } else {
          throw new Error("Video not accessible");
        }
      }
    } catch (error) {
      console.error("Error loading video:", error);
      setVideoError(true);
    } finally {
      setLoading(false);
    }
  };

  const processFfmpegVideo = async (url: string) => {
    try {
      let videoFile = await get(url);
      if (!videoFile) {
        const response = await fetch(url);
        const blob = await response.blob();
        videoFile = new File([blob], "video.mp4", {type: "video/mp4"});
        set(url, videoFile);
      }
      mainStore.loadVideo(videoFile, videoId);
    } catch (error) {
      console.error("Error processing the video:", error);
    }
  };

  useEffect(() => {
    if (videoId) {
      setVideoStreamingUrl(null);
      load();
    }
  }, [videoId]);

  return {
    videoStreamingUrl,
    isLoading,
    videoError,
    data,
    awsKey,
    awsStreamUrl,
    dogId,
    dogName,
    thumbnailUrl,
    isDogActive,
    saveVideoPresets
  };
};
